<template>
    <div class="warpper">
        <div class="page-title flex justify-between align-center">
            <div class="title">自助建站申请</div>
            <router-link to="/organiz/apply/publish">
                <el-button class="publish" type="primary"><i class="el-icon-edit"></i>选择模板</el-button>
            </router-link>
        </div>
        <div class="page-list flex flex-direction align-center margin-top">
            <el-table
                    v-loading="loading"
                    :data="tableData"
                    header-row-class-name="table-header"
                    border
                    style="width: 100%;min-height: 500px">
                <el-table-column prop="name" label="申请模板">
                    <template slot-scope="scope">
                        <div class="table-name">
                            {{scope.row.name}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="create_time" label="申请时间">
                    <template slot-scope="scope">
                        <div class="table-name">
                            {{scope.row.create_time}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="状态">
                    <template slot-scope="scope">
                        <div class="table-name"
                             :class="{yellow:scope.row.status===0,red:scope.row.status === 1,green:scope.row.status===2}">
                            {{scope.row.status | status}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="state" label="操作" width="350">
                    <template slot-scope="scope">
                        <div class="table-operate">
                            <a class="operate active" target="_blank" :href="scope.row.preview_url" v-if="scope.row.status === 2">网站预览</a>
                            <el-button class="operate active" v-if="scope.row.status === 2" @click="toManager(scope.row)">网站管理</el-button>
                            <router-link :to="'/organiz/apply/publish?id='+scope.row.id"
                                         v-if="scope.row.status  === 1">
                                <el-button class="operate active">修改申请</el-button>
                            </router-link>
                            <el-button class="operate" v-if="scope.row.status  === 1" @click="showReason(scope.row.id,scope.row.status_msg)">
                                驳回原因
                            </el-button>
                            <el-button class="operate" v-if="scope.row.status  === 0" @click="deleteItem(scope.row.id)">
                                取消申请
                            </el-button>

                        </div>
                    </template>
                </el-table-column>

            </el-table>
            <el-pagination
                    class="page-all margin-top"
                    background
                    @current-change="init"
                    :current-page.sync="page"
                    :page-size="limit"
                    layout="prev, pager, next, jumper"
                    :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import {setPath} from '@/utils/utils'
    import {getUserApplyWebList,userDeleteApplyWeb,getManageToken} from '@/api/organiz'
    import {zhDate} from "@/utils/utils";
    export default {
        components: {},
        name: "apply",
        data() {
            return {
                tableData: [],
                page: 1,
                limit: 10,
                total: 0,
                loading: false,
            }
        },
        computed: {
            paths() {
                return [...setPath('自助建站申请', '/organiz/apply/list')]
            },
            user() {
                return this.$store.state.user
            }
        },
        created() {
            this.$emit('setIndex', 0, this.paths)
            this.init()
        },
        methods: {
            //  页面加载时请求
            async init() {
                this.loading = true
                let _data = await getUserApplyWebList(this.page, this.limit)
                this.loading = false
                if (_data.status === 200) {
                    _data.data.map(item=>{
                        item.create_time = zhDate(item.create_time * 1000,2)
                    })
                    this.tableData = _data.data
                    this.total = _data.total
                }
            },
            async toManager(item){
                let _data = await getManageToken(item.id)
                if (_data.status === 200) {
                    window.open(`${_data.data.link_url}tiaoz?code=${_data.data.token}`)
                } else {
                    this.$message.error(_data.msg)
                }
            },
            showReason(id,reason) {
                this.$confirm(`您申请的模版不符合要求(${reason})`, "拒绝原因", {
                    confirmButtonText: "去修改",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    this.$router.push('/organiz/apply/publish?id='+id)
                })
            },
            deleteItem(id) {
                this.$confirm("您确定要取消申请该模版吗", "删除", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    this.delPosition(id)
                })
            },
            async delPosition(id) {
                let _data = await userDeleteApplyWeb(id)
                if (_data.status === 200) {
                    this.$message.success(_data.msg)
                    this.init()
                } else {
                    this.$message.error(_data.msg)
                }
            }
        },
        filters: {
            status(type) {
                switch (Number(type)) {
                    case 0:
                        return "审核中";
                    case 1:
                        return "已驳回";
                    case 2:
                        return "已配置";
                    default:
                        return "";
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .warpper {
        flex: 1;
        .page-title {
            border-bottom: 1px solid #d2d2d2;
            min-height: 72px;
            .title {
                font-size: 20px;
                font-weight: bold;
                color: #606060;
                position: relative;
                padding-left: 15px;
                &:after {
                    position: absolute;
                    width: 7px;
                    height: 23px;
                    background-color: #0a2240;
                    border-radius: 3px;
                    content: '';
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            .publish {
                width: 140px;
                height: 46px;
                background-image: linear-gradient(37deg, #ffb400 0%, #fc7802 100%),
                linear-gradient(#0a2140, #0a2140);
                background-blend-mode: normal, normal;
                border-radius: 23px;
                border: none;
            }
        }
        .page-list {
            .table-name {
                font-size: 16px;
                color: #595959;
                text-align: center;
                &.yellow {
                    color: #fe9801;
                }
                &.red {
                    color: #e35555;
                }
                &.green {
                    color: #46e316;
                }
            }

            .table-operate {
                display: flex;
                align-items: center;
                justify-content: space-around;
                .operate {
                    width: 128px;
                    height: 43px;
                    line-height: 43px;
                    padding: 0;
                    background-color: #ffffff;
                    border-radius: 21px;
                    border: solid 1px #0a2240;
                    font-size: 16px;
                    color: #0a2240;
                    text-align: center;
                    &.active {
                        background-color: #0a2240;
                        color: #ffffff;
                    }
                }
            }
        }
    }
</style>